import axios from "axios";
import ApiRoutes from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import AuthService from "../services/Auth/auth.service";
import AppRoutes from "../routes/routeConstants/appRoutes";
import { debounce } from "throttle-debounce";
import { getBaseURL } from "../shared/utils/urlUtils";

const unAuthenticatedRoutes = [
  ApiRoutes.TOKEN,
  ApiRoutes.ISD_CODES,
  ApiRoutes.COUNTRIES,
  ApiRoutes.CORPORATE_EMAIL_VALIDATION,
  ApiRoutes.CORPORATE_SIGN_UP,
  ApiRoutes.USERS_PHONE_VALIDATION,
  ApiRoutes.TIME_ZONE,
  "/provinces",
  "/cities",
  "/zipcodes",
  "/branding_details",
  "/subscriptions",
  "/profiles",
];

export const getHeaders = (): any => {
  let headers;
  const token = localStorage.getItem("token");

  headers = {
    "Ocp-Apim-Subscription-Key": "7fa032f72e00466ca4392db3435cd535",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 40000,
});

axiosInstance.interceptors.request.use((config) => {
  try {
    const token = localStorage.getItem("token");
    const authenticated = unAuthenticatedRoutes?.find((url) =>
      config.url?.includes(url)
    );

    if (token || authenticated) {
      const corporateId = localStorage.getItem("corporateId");
      const practiceId = localStorage.getItem("practiceId");

      if (corporateId) {
        config.url = config.url?.replace(":corporateId", corporateId);
      }
      if (practiceId) {
        config.url = config.url?.replace(":practiceId", practiceId);
      }
      const baseURL = getBaseURL(config?.url);
      if (baseURL !== ApiRoutes.AZURE_BUCKET_BASE_URL)
        config.headers = getHeaders();
      return config;
    } else {
      throw new axios.Cancel("Token is not available. Do login, please.");
    }
  } catch (error) {
    return Promise.reject(error);
  }
});

const error = debounce(500, () => {});

axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
    if (response?.status === 401) {
      AuthService.logout(
        () => {
          window.location.href = AppRoutes.LOGIN;
        },
        () => {},
        () => {}
      );
    }

    const message = response?.data?.errors?.length
      ? response?.data?.errors?.join(" ")
      : response?.data?.message || "Something went wrong. Please try again";

    if (response?.status !== 403) {
      Notification({ message, type: NotificationTypes[response?.status === 404 ? "WARNING" : "ERROR"] });
    }
    return Promise.reject(message);
  }
);

export default axiosInstance;
