const AppRoutes = {
  LOGIN: `https://aligner4dadb2c.b2clogin.com/aligner4dadb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_aligner4dSignInFlow&client_id=f300da0f-36c4-4b04-8e2b-74b02c17d132&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_BASE_URL}/auth&scope=openid&response_type=code&prompt=login`,
  AUTH: "/auth",
  DASHBOARD: "/job-list",
  REGISTER: "/register",
  REGISTER_BASIC_DETAILS: "/register/basic-details",
  REGISTER_BUSINESS_DETAILS: "/register/business-details",
  REGISTER_PAYMENT_DETAILS: "register/payment-details",
  DETAILED_ONBOARDING: "/detailed-onboarding",
  SERVICES: "/services",
  STAFFS: "/staffs",
  CUSTOM_FORMS: "/custom-forms",
  PRACTICES: "/practices",
  PRACTICE_DETAILS: "/practices/:id",
  OVERALL_STAFF_SCHEDULES: "/staffs/schedules",
  STAFF_DETAIL: "/staffs/:staffId",
  PATIENTS: "/patients",
  PATIENT_DETAIL: "/patients/:patientId",
  APPOINTMENT_TEMPLATE: "/appointments/appointment-template",
  CREATE_APPOINTMENT_TEMPLATE: "/appointments/appointment-template/:templateId",
  APPOINTMENT_TEMPLATE_ASSIGN_DATE: "/appointments/assign-dates",
  PAYMENTS: "/payments",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  ANALYTICS: "/analytics",
  CONFIGURATION: "/configuration",
  STAFF_SCHEDULE: "/staffs/:staffId/schedule",
  RESOURCES: "/configuration/resources",
  NOTE_TEMPLATE: "/configuration/note-template",
  STATUS_MANAGEMENT_STATEMENT: "/configuration/note-template/statement",
  STATUS_MANAGEMENT_X_RAY: "/configuration/note-template/x-ray",
  TREATMENT_PLANS: "/configuration/treatment-plans",
  TREATMENT_PLAN_DETAILS: "/3d-views/treatment-plans/:id",
  TREATMENT_PLAN_TEMPLATE_DETAILS: "/3d-views/treatment-plans-template/:id",
  CORPORATE_OBJECTS: "/configuration/corporate-objects",
  CORPORATE_OBJECT_DETAIL:
    "/configuration/corporate-objects/:corporateObjectId",
  CORPORATE_OBJECT_LINKS:
    "/configuration/corporate-objects/:corporateObjectId/object-links",
  SETS: "/configuration/sets",
  VIEWS_3D: "/3d-views",
  SET_DETAIL: "/3d-views/sets/:setId",
  PROCEDURES: "/configuration/procedures",
  PROCEDURE_DETAIL: "/3d-views/procedures/:procedureId",
  STATUS_MANAGEMENT: "/configuration/status-management",
  APPOINTMENT_STATUS: "/configuration/status-management/appointment-status",
  APPOINTMENT_TYPE: "/configuration/status-management/appointment-type",
  PATIENT_STATUS: "/configuration/status-management/patient-status",
  APPOINTMENTS: "/",
  INTERACTION:
    "/3d-views/patient-profiles/:patientProfileId/interactions/:interactionId/detail",
  ADD_CONTRACT: "/services/contract",
  EDIT_CONTRACT: "/services/contract/:contractId/edit",
  CONTRACT_DETAIL: "/services/contracts/:contractId",
  ROLES: "/roles-and-responsibility",
  RESTRICTED_PAGE: "/restricted-page",
  CORPORATE_DETAILS: "/corporate-details",
  REDIRECT_PAGE: "/auth/redirect",
  EMAIL_APP_CUSTOM_FORMS: `${process.env.REACT_APP_EMAIL_BASE_URL}/custom-forms/:customFormId`,
  EMAIL_APP_CONTRACT: `${process.env.REACT_APP_EMAIL_BASE_URL}/contract-terms/:contractTermId`,
};

export default AppRoutes;
